import { get, isNull, isPlainObject, isString, isUndefined } from 'lodash';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useIsPublishedOrRunVersionOpen } from '../../../../model/DataAppContext';
import { SENTENCE_INPUT_MARGIN } from '../../../../standard-components/containers';
import InputConfigPanel from '../../InputConfigPanel';
import StepContext, { useStepInputValue } from '../../StepContext';
import SelectInput from './SelectInput';
import { renderChildren } from './SentenceRendererCore';

const canBeConfigured = [
  'bool',
  'dataset',
  'string',
  'column',
  'value_or_column',
  'number',
  'array',
];
const handlesOwnChildren = ['array', 'object', 'sentence', 'column'];
const cannotHaveChildren = ['bool', 'dataset', 'number', 'string'];
const handlesOwnPublishedVersion = ['dataset'];

export const PublishedValueText = styled.span`
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  margin: ${SENTENCE_INPUT_MARGIN};
  text-transform: ${(p) => p.capitalize && 'capitalize'};
  border-bottom: 2px solid ${(p) => p.theme.color.secondary.lightBorder};
`;

const InputAndConfigButtonContainer = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
`;

const shouldShowConfigButton = (
  { type, children },
  isPublishedOrRunVersionOpen
) => {
  if (isPublishedOrRunVersionOpen) return false;

  if (canBeConfigured.includes(type)) {
    if (!handlesOwnChildren.includes(type)) return true;

    if (children && children.length > 0) return 'embed';
    else return true;
  }

  return false;
};

export const withCoreInputBehavior = (Input, allowMultiple = false) => {
  return (props) => {
    const {
      schema = {},
      reportInputValue,
      getCurrentValue,
      currentPath,
      shouldRenderAsInteractive,
    } = props;
    const { stepIsEditable, user_provided, interactive } = useContext(
      StepContext
    );
    let inputIsEnabled =
      props.disabled !== undefined ? !props.disabled : stepIsEditable;
    const isPublishedOrRunVersionOpen = useIsPublishedOrRunVersionOpen();
    let shouldShowPublishedVersion = isPublishedOrRunVersionOpen;
    if (
      isPublishedOrRunVersionOpen &&
      (user_provided || interactive[currentPath] || shouldRenderAsInteractive)
    ) {
      shouldShowPublishedVersion = false;
    }

    let type, value;
    if (getCurrentValue) {
      ({ type, value } = getCurrentValue(schema.id) || {});
    }

    let currentValue;
    if (
      type === 'static' ||
      (type === 'variable' && schema.type === 'dataset')
    ) {
      currentValue = value;
    }

    useEffect(() => {
      if (schema.default_value && (isUndefined(value) || isNull(value))) {
        reportInputValue(schema.id, {
          type: 'static',
          value: schema.default_value,
        });
      }
    }, []);

    if (schema.hidden) {
      return <></>;
    }

    if (schema.dependencies) {
      let dependencyMet = false;
      schema.dependencies.forEach((dependency) => {
        // Handle case where dependency array has ['id1'] which should check for
        // whether the input with id 'id1' has any value set
        if (isString(dependency)) {
          const depInputValue = useStepInputValue(dependency, currentPath);
          if (!isUndefined(depInputValue)) {
            dependencyMet = true;
          }
          // Handle case where dependency array has [{'id1': specificValue}] which should check for
          // whether the input with id 'id1' has a specific value set
        } else if (isPlainObject(dependency)) {
          const depId = Object.keys(dependency)[0];
          const depTarget = dependency[depId];
          const depCurrentValueObj = useStepInputValue(depId, currentPath);
          const depCurrentValue = get(depCurrentValueObj, 'value');

          if (
            isPlainObject(depTarget) &&
            get(depTarget, 'type') === 'should_not_match' &&
            get(depTarget, 'value') !== depCurrentValue
          ) {
            dependencyMet = true;
          }

          if (depTarget === depCurrentValue) {
            dependencyMet = true;
          }
        }
      });

      if (!dependencyMet) {
        if (schema.dependency_type === 'disable') {
          inputIsEnabled = false;
        } else {
          return <></>;
        }
      }
    }

    const showConfigButton = shouldShowConfigButton(
      schema,
      isPublishedOrRunVersionOpen
    );
    const embedConfigButton = showConfigButton === 'embed';
    const inputConfigButton = showConfigButton && (
      <InputConfigPanel pathToInput={props.currentPath} />
    );

    const handleChildren =
      !handlesOwnChildren.includes(schema.type) &&
      !isUndefined(schema.children);
    const handledChildren =
      handleChildren &&
      renderChildren({
        children: schema.children,
        reportInputValue,
        getCurrentValue,
        currentPath: props.currentPath,
        parentId: schema.id,
        renderAsSibling: true,
      });

    let valueShownByInput = isUndefined(currentValue)
      ? schema.default_value
      : currentValue;
    if (schema.options) {
      return (
        <>
          <InputAndConfigButtonContainer>
            <SelectInput
              id={schema.id}
              options={schema.options}
              placeholder={schema.placeholder_value}
              allowMultiple={allowMultiple}
              value={valueShownByInput}
              disabled={!inputIsEnabled}
              reportInputValue={reportInputValue}
              shouldShowPublishedVersion={shouldShowPublishedVersion}
              currentPath={props.currentPath}
              size={isPublishedOrRunVersionOpen && 'large'}
            />
            {inputConfigButton}
          </InputAndConfigButtonContainer>
          {handledChildren}
        </>
      );
    }

    if (
      shouldShowPublishedVersion &&
      cannotHaveChildren.includes(schema.type) &&
      !handlesOwnPublishedVersion.includes(schema.type)
    ) {
      return <PublishedValueText>{`${currentValue}`}</PublishedValueText>;
    }

    const inputToDisplay = (
      <Input
        {...props}
        disabled={!inputIsEnabled}
        currentValue={valueShownByInput}
        shouldShowPublishedVersion={shouldShowPublishedVersion}
        size={isPublishedOrRunVersionOpen && 'large'}
        suffix={embedConfigButton && inputConfigButton}
        stepInteractivity={interactive}
      />
    );

    return (
      <>
        {inputConfigButton && !embedConfigButton ? (
          <InputAndConfigButtonContainer>
            {inputToDisplay}
            {inputConfigButton}
          </InputAndConfigButtonContainer>
        ) : (
          inputToDisplay
        )}
        {handledChildren}
      </>
    );
  };
};
