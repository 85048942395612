import React from 'react';
import { makeAPICall } from '../api/useAPI';
import { Menu, Modal, message } from 'antd';
import {
  ExclamationCircleOutlined,
  DeleteTwoTone,
  DeleteOutlined,
} from '@ant-design/icons';

export default (props) => {
  const { appId, onAppDeleteComplete } = props;
  const DeleteApp = async () => {
    const hideLoadingMessage = message.loading('Deleting app...', 0);
    const [deletedApp, error] = await makeAPICall({
      method: 'POST',
      endpoint: `/app/delete?app_id=${appId}`,
      errorNotification: true,
    });
    hideLoadingMessage();
    if (error) {
      message.warn('Error deleting app, please try again');
    } else {
      message.success('App deleted');
      onAppDeleteComplete(appId);
    }
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this app?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        DeleteApp();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const MenuFunctions = { DeleteApp: showDeleteConfirm };
  const handleMenuClick = (e) => {
    e.domEvent.stopPropagation();
    const functionName = e.key;
    MenuFunctions[functionName]();
  };

  return (
    <Menu {...props} onClick={handleMenuClick}>
      <Menu.Item danger key="DeleteApp" icon={<DeleteOutlined />}>
        Delete App
      </Menu.Item>
    </Menu>
  );
};
