import { isEqual, isUndefined } from 'lodash';
import { API_WEBSOCKET_PREFIX } from '../api/useAPI';
import { getAccessToken, refreshToken } from './User';

export default class NotificationConnection {
  constructor() {
    this.tokenHasBeenRefreshed = false;
  }

  openWebsocket = (url, onmessage) => {
    // Let us open a web socket
    var ws = new WebSocket(url);

    ws.onopen = () => {
      // Web Socket is connected, send data using send()
      console.log('Connection is open...');
    };

    ws.onmessage = onmessage;

    ws.onclose = () => {
      // websocket is closed.
      console.log('Connection is closed...');
      this.currentWebsocket = undefined;
    };

    return ws;
  };

  connect = async (
    appId,
    versionId,
    instanceId,
    teamId,
    processNotification
  ) => {
    const newClientId = appId + '_' + versionId + '_' + instanceId;
    if (
      isEqual(newClientId, this.clientId) &&
      !isUndefined(this.currentWebsocket)
    ) {
      return;
    }
    this.clientId = newClientId;

    if (!this.tokenHasBeenRefreshed) {
      this.tokenHasBeenRefreshed = true;
      await refreshToken();
    }

    const accessToken = await getAccessToken();
    const url = `${API_WEBSOCKET_PREFIX}/ws/order_events/${this.clientId}?access_token=${accessToken}&team_id=${teamId}`;
    this.currentWebsocket = this.openWebsocket(url, function (evt) {
      var received_msg = evt.data;
      // console.log('Message is received...', received_msg);
      if (received_msg) {
        processNotification(JSON.parse(received_msg));
      }
    });
  };

  close = () => {
    this.currentWebsocket && this.currentWebsocket.close();
    this.currentWebsocket = undefined;
  };
}
