import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  MoreOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CheckCircleTwoTone,
  ReloadOutlined,
} from '@ant-design/icons';
import { CARD_CONTAINER_PADDING } from '../../../standard-components/containers';
import { Dropdown, Menu } from 'antd';
import StepContext from '../StepContext';
import confirm from 'antd/lib/modal/confirm';
import { useIsPublishedOrRunVersionOpen } from '../../../model/DataAppContext';
import { InnerShadowTitle } from '../../../standard-components/typography';
import { useRef } from 'react';

const StepControlsContainer = styled.div`
  display: flex;
  /* min-width: 88px;
  max-width: 88px; */
  padding: ${CARD_CONTAINER_PADDING};
  margin-right: 2px; // padding + 2 = 16
  justify-content: flex-end;
`;

const StepNumber = styled(InnerShadowTitle)`
  margin-left: 15px;
  background-color: #c7c7c7;
`;

const ControlOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  margin-left: 6px;
  background-color: ${(p) => p.theme.color.grayscale.white};
  border: 1px solid
    ${(p) =>
      p.hideBorder ? 'transparent' : p.theme.color.grayscale.faintGray4};
  cursor: pointer;
  transition: border 0.3s;

  :hover {
    border: 1px solid
      ${({ theme: { color }, hideBorder }) =>
        hideBorder ? 'transparent' : color.grayscale.dark};
  }
`;

export const VisibleEyecon = styled(EyeOutlined)`
  color: ${({ theme: { color } }) => color.primary.dark};
  font-size: 24px;
`;

export const NotVisibleEyecon = styled(EyeInvisibleOutlined)`
  color: ${({ theme: { color } }) => color.grayscale.faintGray1};
  font-size: 24px;
`;

export const VisibilityOptions = {
  auto: 'auto',
  always: 'always',
  never: 'never',
};

const MoreOptionsMenu = () => {
  const {
    visible,
    saveIsVisible,
    onDeleteButtonClicked,
    onReRunClicked,
    isDraft,
    stepStatus,
  } = useContext(StepContext);

  function showDeleteConfirm() {
    confirm({
      title: 'Are you sure delete this step?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        onDeleteButtonClicked();
      },
      onCancel() {},
    });
  }

  let parsedVisible = visible;
  if (visible === true) {
    parsedVisible = 'auto';
  } else if (visible === false) {
    parsedVisible = 'never';
  }

  return (
    <Menu selectedKeys={[parsedVisible]}>
      <Menu.Item
        onClick={onReRunClicked}
        icon={<ReloadOutlined />}
        disabled={isDraft || stepStatus === 'RUNNING'}
      >
        Re-Run Step
      </Menu.Item>
      <Menu.SubMenu
        key="sub1-2"
        title="In-App Visibility"
        icon={<EyeInvisibleOutlined />}
      >
        <Menu.Item
          key={VisibilityOptions.auto}
          onClick={() => saveIsVisible(VisibilityOptions.auto)}
        >
          Auto
        </Menu.Item>
        <Menu.Item
          key={VisibilityOptions.always}
          onClick={() => saveIsVisible(VisibilityOptions.always)}
        >
          Always Show
        </Menu.Item>
        <Menu.Item
          key={VisibilityOptions.never}
          onClick={() => saveIsVisible(VisibilityOptions.never)}
        >
          Don't Show
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item onClick={showDeleteConfirm} icon={<DeleteOutlined />} danger>
        Delete Step
      </Menu.Item>
    </Menu>
  );
};

const EditingControls = ({ stepControlsRef }) => {
  return (
    <>
      <Dropdown
        overlay={MoreOptionsMenu()}
        arrow
        placement="bottomCenter"
        getPopupContainer={() => stepControlsRef && stepControlsRef.current}
      >
        <ControlOption>
          <MoreOutlined />
        </ControlOption>
      </Dropdown>
    </>
  );
};

export const StepControls = ({ stepIndex }) => {
  const stepControlsRef = useRef(null);
  const isPublishedOrRunVersionOpen = useIsPublishedOrRunVersionOpen();
  const stepNumber = stepIndex !== false && (
    <StepNumber>{stepIndex + 1}.</StepNumber>
  );

  if (isPublishedOrRunVersionOpen)
    return <StepControlsContainer>{stepNumber}</StepControlsContainer>;

  return (
    <StepControlsContainer ref={stepControlsRef}>
      <EditingControls stepControlsRef={stepControlsRef} />
      {stepNumber}
    </StepControlsContainer>
  );
};
