import { Tag, Tree, TreeSelect } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { withCoreInputBehavior } from './CoreInputHOC';
import { useState } from 'react';
import styled from 'styled-components';
import { SENTENCE_INPUT_MARGIN } from '../../../../standard-components/containers';
import { useEffect } from 'react';
import { makeAPICall } from '../../../../api/useAPI';
import {
  useAppId,
  useInstanceId,
} from '../../../../model/DataAppMetadataContextHelpers';

const StyledTreeSelect = styled(TreeSelect)`
  margin: ${SENTENCE_INPUT_MARGIN};
`;

const OptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .ant-tag {
    margin-left: 10px;
  }
`;

const TreeSectionTitle = styled.span`
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  color: ${(p) => p.theme.color.grayscale.faintGray1};
`;

const CustomValueOrOptionSelectInput = ({
  reportInputValue,
  value: currentValue,
  disabled,
  placeholder,
  options,
  providedOptionsKey,
  providedOptionsLabel,
  typedOptionKey,
  typedOptionLabel,
  size,
  config = {},
}) => {
  const [formattedCurrentValue, setFormattedCurrentValue] = useState();
  const [currentValueAsOption, setCurrentValueAsOption] = useState();
  const [currentlyEnteredValue, setCurrentlyEnteredValue] = useState();
  const configDatasetName = get(config, 'dataset.name', 'Dataset');
  const configDatasetVariable = get(config, 'dataset.variable');
  const configColumnName = get(config, 'column');
  const [datasetColumnValueOptions, setDatasetColumnValueOptions] = useState();
  const appId = useAppId();
  const instanceId = useInstanceId();

  useEffect(() => {
    const fetchOptions = async () => {
      const [options, error] = await makeAPICall({
        endpoint: `/app/variable/unique-values?app_id=${appId}&instance_id=${instanceId}&variable_name=${configDatasetVariable}&column_name=${configColumnName}`,
      });

      if (options && !error) {
        setDatasetColumnValueOptions(options);
      }
    };

    if (configDatasetVariable && configColumnName) {
      fetchOptions();
    }
  }, [configDatasetVariable, configColumnName, appId, instanceId]);

  useEffect(() => {
    if (currentValue) {
      const typeKey = Object.keys(currentValue);
      const selection = currentValue[typeKey];
      setFormattedCurrentValue(`${typeKey}:${selection}`);

      // Columns are options by default. Existing primitive_value's must be added.
      if (typeKey[0] === typedOptionKey) {
        setCurrentValueAsOption(selection);
      }
    }
  }, [currentValue]);

  const getValueToSelect = (typeKey, value) => `${typeKey}:${value}`;

  const onColumnSelect = (selectedValue) => {
    const typeKey = selectedValue.split(':')[0];
    const selection = selectedValue.substring(typeKey.length + 1);
    reportInputValue(undefined, {
      type: 'static',
      value: { [typeKey]: selection },
    });
  };

  const getTreeOptionData = (typeKey, value, tagLabel, tagColor) => {
    const valueToSelect = getValueToSelect(typeKey, value);
    const option = (
      <OptionRow>
        <span>{value}</span>
        <Tag color={tagColor}>{tagLabel}</Tag>
      </OptionRow>
    );

    return {
      title: option,
      value: valueToSelect,
    };
  };

  const columnOptionsData = options.map((col) =>
    getTreeOptionData(providedOptionsKey, col, providedOptionsLabel, 'orange')
  );

  const nonColumnOptions = [];
  const isFromColumnValueOptionsSet = (option) => {
    return (
      datasetColumnValueOptions && datasetColumnValueOptions.includes(option)
    );
  };
  if (currentValueAsOption) {
    if (!isFromColumnValueOptionsSet(currentValueAsOption)) {
      nonColumnOptions.push(currentValueAsOption);
    }
  }
  if (currentlyEnteredValue && currentlyEnteredValue !== currentValueAsOption) {
    if (!isFromColumnValueOptionsSet(currentlyEnteredValue)) {
      nonColumnOptions.push(currentlyEnteredValue);
    }
  }
  const nonColumnOptionsData = nonColumnOptions.map((nonColumnOption) =>
    getTreeOptionData(
      typedOptionKey,
      nonColumnOption,
      typedOptionLabel,
      'purple'
    )
  );

  let treeData = [];

  const treeExpandedKeys = [];
  const columnChoiceKey = 'columns_choice';
  const customValuesChoiceKey = 'custom_values_choice';
  const datasetColumnValuesChoiceKey = 'dataset_column_values_choice';

  if (columnOptionsData.length > 0) {
    treeData.push({
      title: <TreeSectionTitle>{configDatasetName} Columns</TreeSectionTitle>,
      value: columnChoiceKey,
      selectable: false,
      children: columnOptionsData,
    });
  }
  if (nonColumnOptionsData.length > 0) {
    treeExpandedKeys.push(customValuesChoiceKey);
    treeData.push({
      title: <TreeSectionTitle>Custom Values</TreeSectionTitle>,
      value: customValuesChoiceKey,
      selectable: false,
      children: nonColumnOptionsData,
    });
  }

  if (datasetColumnValueOptions) {
    treeExpandedKeys.push(datasetColumnValuesChoiceKey);
    treeData.push({
      title: <TreeSectionTitle>{configColumnName} Options</TreeSectionTitle>,
      value: datasetColumnValuesChoiceKey,
      selectable: false,
      children: datasetColumnValueOptions.map((nonColumnOption) =>
        getTreeOptionData(
          typedOptionKey,
          nonColumnOption,
          typedOptionLabel,
          'purple'
        )
      ),
    });
  }

  const onInputKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      const valueToSelect = getValueToSelect(
        typedOptionKey,
        currentlyEnteredValue
      );
      onColumnSelect(valueToSelect);
    }
  };

  if (treeExpandedKeys.length === 0) {
    treeExpandedKeys.push(columnChoiceKey);
  }
  return (
    <StyledTreeSelect
      style={{ minWidth: 200 }}
      showSearch
      onSearch={setCurrentlyEnteredValue}
      value={formattedCurrentValue}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeData={treeData}
      // treeDefaultExpandAll
      treeDefaultExpandedKeys={treeExpandedKeys}
      onChange={onColumnSelect}
      onInputKeyDown={onInputKeyDown}
      placeholder={placeholder}
      size={size}
      disabled={disabled}
    />
  );
};

export default withCoreInputBehavior(CustomValueOrOptionSelectInput);
