import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { StepControls, VisibilityOptions } from './StepBody/StepControls';
import {
  useDeleteStep,
  usePublicSaveEditedStep,
  useRemoveDraftStep,
  useSaveEditedStep,
  useSaveNewStep,
  useStepStatus,
} from '../../model/DataAppStepsContextHelpers';
import ConnectorLine from './StepBody/ConnectorLine';
import StepContext, { useIsAutoSavable } from './StepContext';
import { get, isEqual, isUndefined, isEmpty, keys } from 'lodash';
import {
  getViewingAppAsPublic,
  useAppStatus,
  useInstanceId,
} from '../../model/DataAppMetadataContextHelpers';
import {
  useIsPublishedVersionOpen,
  useIsRunVersionOpen,
} from '../../model/DataAppContext';
import CommentStep, {
  CommentStepV1,
} from './StepRenderers/CommentRenderers/CommentStep';
import StepOutputsList from './StepOutput/StepOutputsList';
import StepBody from './StepBody/StepBody';
import DashboardRenderer from './StepRenderers/DashboardRenderers/DashboardRenderer';
import { notification } from 'antd';
import { STEP_STATUS } from './StepBody/StepStatusIndicator';

const StepContainer = styled.div`
  display: flex;
`;

const StepContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  max-width: 100%;

  > *:first-child {
    width: 100%;
  }
`;

const StepConnectorLineAndOutputsContainer = styled.div`
  display: flex;
  padding-left: 4px;
`;

export const Step = (stepInfo) => {
  const {
    id,
    name,
    type,
    leaf,
    currentStepIndex,
    isLastStep,
    afterStepId,
    blockId,
    appBlockId,
    inputs,
    outputs,
    interactive = {},
    user_provided = false,
    visible = true,
    isDraft = false,
    onlyShowInputsAndOutputs,
    showStepIndex = true,
  } = stepInfo;
  const isPublishedVersionOpen = useIsPublishedVersionOpen();
  const isRunVersionOpen = useIsRunVersionOpen();
  const viewingAsPublic = getViewingAppAsPublic();
  const isPublishedOrRunVersionOpen =
    isPublishedVersionOpen || isRunVersionOpen;
  const noOutputs = get(outputs, 'length', 0) === 0;
  const hasInteractiveInputs = keys(interactive).length > 0;
  const stepStatus = useStepStatus(id);
  const stepHasError = stepStatus === STEP_STATUS.FAILURE;

  const [stepIsExpanded, setStepIsExpanded] = useState(
    isDraft || onlyShowInputsAndOutputs || stepHasError
  );
  const [stepIsDirty, setStepIsDirty] = useState(false);
  const [draftStepName, setDraftStepName] = useState();
  const [draftInteractivityDef, setDraftInteractivityDef] = useState(
    interactive
  );
  const [draftIsUserProvided, setDraftIsUserProvided] = useState(user_provided);
  const [draftIsVisible, setDraftIsVisible] = useState(visible);
  const isAutoSavable = useIsAutoSavable(type);
  //TODO change this to false when required input checking is built
  const [stepStateIsSaveable, setStepStateIsSaveable] = useState(true);
  const [stepSentenceInputValues, setStepSentenceInputValues] = useState(
    inputs || {}
  );
  const [previousInputsProp, setPreviousInputsProp] = useState(inputs);
  const [stepActionsInProgress, setStepActionsInProgress] = useState({
    add: false,
    save: false,
    delete: false,
  });
  const instanceId = useInstanceId();
  const saveNewStep = useSaveNewStep();
  const saveEditedStep = useSaveEditedStep();
  const publicSaveEditedStep = usePublicSaveEditedStep();
  const deleteStep = useDeleteStep();
  const removeDraftStep = useRemoveDraftStep();
  let stepIsEditable =
    instanceId &&
    (!isPublishedOrRunVersionOpen || user_provided || hasInteractiveInputs);
  if (isPublishedVersionOpen) {
    stepIsEditable = false;
  }
  const hiddenStep = type === 'INVISIBLE';

  const resetStepToSavedState = () => {
    setStepSentenceInputValues(inputs || {});
    setStepIsDirty(false);
  };

  useEffect(() => {
    if (stepIsEditable && isPublishedOrRunVersionOpen) {
      if (!['SUCCESS', 'RUNNING', 'NOT_DONE'].includes(stepStatus)) {
        setStepIsDirty(true);
      } else if (stepStatus === 'SUCCESS') {
        setStepIsDirty(false);
      }
    }
  }, [stepIsEditable, isPublishedOrRunVersionOpen, stepStatus]);

  useEffect(() => {
    if (!isEqual(inputs, previousInputsProp)) {
      setPreviousInputsProp(inputs);
      resetStepToSavedState();
    }
  }, [inputs]);

  const onAddButtonClicked = async () => {
    setStepActionsInProgress((state) => ({ ...state, add: true }));
    const success = await saveNewStep(
      blockId,
      stepSentenceInputValues,
      afterStepId,
      appBlockId,
      draftStepName,
      draftIsVisible,
      draftIsUserProvided,
      draftInteractivityDef,
      type
    );
    if (success) {
      setStepIsDirty(false);
    }
    setStepActionsInProgress((state) => ({ ...state, add: false }));
  };

  const saveTitleChange = async (newTitle) => {
    const oldDraftStepName = draftStepName;
    setDraftStepName(newTitle);
    if (isDraft) {
      return true; // will be saved when step is added
    }

    const success = await saveEditedStep(
      { step_id: id, name: newTitle },
      false
    );

    if (!success) {
      notification.error({
        message: 'Something went wrong',
        description:
          "Couldn't change the step's title. Please check your internet connection and try again.",
      });
      setDraftStepName(oldDraftStepName);
    }

    return success;
  };

  const saveUserProvided = async (_isUserProvided) => {
    const oldIsUserProvided = draftIsUserProvided;
    setDraftIsUserProvided(_isUserProvided);
    if (isDraft) {
      return true; // will be saved when step is added
    }

    const success = await saveEditedStep(
      {
        step_id: id,
        user_provided: _isUserProvided,
      },
      false
    );

    if (!success) {
      notification.error({
        message: 'Something went wrong',
        description:
          "Couldn't set the entire step to be interactive in app. Please check your internet connection and try again.",
      });
      setDraftIsUserProvided(oldIsUserProvided);
    }

    return success;
  };

  const saveIsVisible = async (_isVisible) => {
    const oldIsVisible = draftIsVisible;
    setDraftIsVisible(_isVisible);
    if (isDraft) {
      return true; // will be saved when step is added
    }

    const success = await saveEditedStep(
      { step_id: id, visible: _isVisible },
      false
    );
    if (!success) {
      notification.error({
        message: 'Something went wrong',
        description:
          "Couldn't set the step to be visible by default in app. Please check your internet connection and try again.",
      });
      setDraftIsVisible(oldIsVisible);
    }
  };

  const updateInputInteractivity = async (pathToInput, interactive) => {
    const oldInteractive = draftInteractivityDef;
    const newInteractive = {
      ...draftInteractivityDef,
      [pathToInput]: interactive,
    };
    setDraftInteractivityDef(newInteractive);
    if (isDraft) {
      return true; // will be saved when step is added
    }

    const success = await saveEditedStep(
      { step_id: id, interactive: newInteractive },
      false
    );
    if (!success) {
      notification.error({
        message: 'Something went wrong',
        description:
          "Couldn't set input to be interactive in app. Please check your internet connection and try again.",
      });
      setDraftInteractivityDef(oldInteractive);
    }
  };

  const onSaveButtonClicked = async () => {
    setStepActionsInProgress((state) => ({ ...state, save: true }));
    let success;
    if (viewingAsPublic) {
      success = await publicSaveEditedStep(id, stepSentenceInputValues);
    } else {
      success = await saveEditedStep(
        {
          step_id: id,
          inputs: stepSentenceInputValues,
        },
        true
      );
    }
    if (success) {
      setStepIsDirty(false);
    }
    setStepActionsInProgress((state) => ({ ...state, save: false }));
  };

  const onReRunClicked = async () => {
    return onSaveButtonClicked();
  };

  const onDeleteButtonClicked = async () => {
    if (isDraft) {
      removeDraftStep(currentStepIndex);
      return;
    }
    setStepActionsInProgress((state) => ({ ...state, delete: true }));
    const success = await deleteStep(id);
    if (!success) {
      setStepActionsInProgress((state) => ({ ...state, delete: false }));
    }
  };

  const debouncedSaveStep = useDebouncedCallback(
    onSaveButtonClicked,
    500 // delay in ms
  );

  const updateSentenceInputValues = (sentenceInputValueChanges) => {
    if (!stepIsEditable) return;

    const updatedSentenceInputValues = {
      ...stepSentenceInputValues,
      ...sentenceInputValueChanges,
    };
    if (!isEqual(updatedSentenceInputValues, stepSentenceInputValues)) {
      setStepIsDirty(true);
    }
    setStepSentenceInputValues(updatedSentenceInputValues);
  };

  useEffect(() => {
    const stepNotYetAdded = !id;
    const addingNotInProgress = !stepActionsInProgress.add;
    const inputValuesExist = !isEmpty(stepSentenceInputValues);

    if (isAutoSavable) {
      if (inputValuesExist && stepNotYetAdded && addingNotInProgress) {
        onAddButtonClicked();
      }

      if (!stepNotYetAdded && stepIsDirty) {
        debouncedSaveStep();
      }
    }
  }, [stepSentenceInputValues]);

  const contextValue = {
    ...stepInfo,
    name: draftStepName || name,
    visible: draftIsVisible,
    user_provided: draftIsUserProvided,
    interactive: draftInteractivityDef,
    stepIsDirty,
    stepSentenceInputValues,
    stepStateIsSaveable,
    stepActionsInProgress,
    stepIsEditable,
    stepStatus,
    onAddButtonClicked,
    cancelAdd: () => removeDraftStep(currentStepIndex),
    onSaveButtonClicked,
    onReRunClicked,
    onDeleteButtonClicked,
    updateSentenceInputValues,
    resetStepToSavedState,
    setStepStateIsSaveable,
    saveUserProvided,
    saveIsVisible,
    updateInputInteractivity,
    onlyShowInputsAndOutputs,
    viewingAsPublic,
  };

  const customStepBodyRenderers = {
    COMMENT: CommentStepV1,
    COMMENT_V2: CommentStep,
    DASHBOARD: DashboardRenderer,
  };

  const shouldAlwaysShowFullBody = {
    COMMENT_V2: true,
  };

  const StepBodyElement = customStepBodyRenderers[type] || StepBody;

  const stepIsNotDone = stepInfo.status === 'NOT_DONE';
  let showFullBody =
    !onlyShowInputsAndOutputs ||
    hasInteractiveInputs ||
    noOutputs ||
    shouldAlwaysShowFullBody[type];

  const stepOutputs = (
    <StepOutputsList
      outputs={outputs}
      isOfLeafStep={leaf}
      stepIsEditable={stepIsEditable}
      onlyShowingInputsAndOutputs={onlyShowInputsAndOutputs}
      expandedByDefault={onlyShowInputsAndOutputs}
    />
  );

  const stepBody = (
    <>
      {currentStepIndex === 0 && (
        <StepConnectorLineAndOutputsContainer>
          <ConnectorLine
            invisible={isPublishedOrRunVersionOpen}
            disabled={isPublishedOrRunVersionOpen}
            afterStepId={null}
            isBeforeFirstStep={true}
          />
        </StepConnectorLineAndOutputsContainer>
      )}
      <StepBodyElement
        key={id + currentStepIndex}
        name={draftStepName || name}
        type={type}
        isDraft={isDraft}
        stepIsExpanded={stepIsExpanded}
        saveTitleChange={stepIsEditable ? saveTitleChange : undefined}
        setStepIsExpanded={setStepIsExpanded}
        isHiddenStep={hiddenStep}
        shouldShowPublishedVersion={isPublishedOrRunVersionOpen}
      />
      <StepConnectorLineAndOutputsContainer>
        <ConnectorLine
          invisible={onlyShowInputsAndOutputs || isPublishedOrRunVersionOpen}
          disabled={isPublishedOrRunVersionOpen}
          showFullButton={isLastStep}
          afterStepId={id}
        />
        {!hiddenStep && !isUndefined(outputs) && stepOutputs}
      </StepConnectorLineAndOutputsContainer>
    </>
  );

  return (
    <StepContext.Provider value={contextValue}>
      <StepContainer id={`step-${currentStepIndex + 1}`}>
        {!onlyShowInputsAndOutputs && (
          <StepControls stepIndex={showStepIndex && currentStepIndex} />
        )}
        <StepContentWrapper>
          {showFullBody ? stepBody : stepOutputs}
        </StepContentWrapper>
      </StepContainer>
    </StepContext.Provider>
  );
};
