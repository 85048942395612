import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, Checkbox, Card, Button } from 'antd';
import IntersectLogo from '../images/Logo.svg';
import firebase from 'firebase/app';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Loading from '../standard-components/Loading';
import { useRouteMatch } from 'react-router-dom';
import { systemColors } from '../colors/systemColors';
import { fetchUserAndToken } from '../model/User';
import { useAPI } from '../api/useAPI';
import { identifyUser } from '../settings-components/ILAnalytics';
import { getContinueToURLParam } from './LoginPage';
import { Helmet } from 'react-helmet';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
  padding-bottom: 40px;
`;

const SignUpForm = styled(Card)`
  max-width: 100%;
  width: 460px;
  margin: auto;
  padding: 10px 50px;
  background: ${systemColors.background.fill};
  margin-top: 3%;
  border-radius: 4px;
  border: ${systemColors.background.border};
`;

const Title = styled.p`
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 300;
  margin-bottom: 25px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 15px;
`;

const StyledPasswordInput = styled(Input.Password)`
  margin-bottom: 15px;
`;

const Logo = styled.img`
  margin-top: 50px;
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: red;
  margin: 10px;
`;

const TCPPWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

const TCPPText = styled.p`
  font-size: 14px;
  margin-left: 13px;
  text-align: left;
`;

const SubmitButton = styled(Button)`
  margin: 15px;
`;

const LoginButton = () => {
  const history = useHistory();
  const location = useLocation();
  const continueTo = getContinueToURLParam(location);

  if (continueTo) {
    return (
      <SubmitButton
        size="large"
        onClick={() =>
          history.replace(`/login?continueTo=${encodeURIComponent(continueTo)}`)
        } // todo
      >
        Log In
      </SubmitButton>
    );
  }

  return (
    <Link to={`/login`}>
      <SubmitButton size="large">Log In</SubmitButton>
    </Link>
  );
};

export default () => {
  const [fields, setFields] = useState({
    fullName: '',
    emailAddress: undefined,
    team_name: undefined,
    passwordOne: '',
    passwordTwo: '',
  });

  const [error, setError] = useState('');
  const [tcpp, setTcpp] = useState(false);
  const [signupInProgress, setSignupInProgress] = useState(false);
  const [disabledFields, setDisabledFields] = useState({});

  const match = useRouteMatch();
  const signupLink = match.params.signupLink;
  const [fetchedSignupInfo, { isLoading }] = useAPI({
    endpoint: `/user/decrypt-signup-link/${signupLink}`,
    suppress: !signupLink,
  });

  useEffect(() => {
    if (!fetchedSignupInfo) return;
    const { email, team_name } = fetchedSignupInfo;
    setFields({
      emailAddress: email,
      team_name,
    });

    setDisabledFields({
      emailAddress: !!email,
      team_name: !!team_name,
    });
  }, [fetchedSignupInfo]);

  useEffect(() => {
    window.analytics.page('SignUp');
  }, []);

  const handleChangeInput = (field, value) => {
    setFields((fields) => ({ ...fields, [field]: value }));
    setError('');
  };

  const handleChangeTcpp = () => {
    setTcpp((tcpp) => !tcpp);
    setError('');
  };

  const stringNotEmpty = (x) => {
    return x.length > 0;
  };

  const incompleteInputs = () => {
    const inputArray = [
      fields.emailAddress,
      fields.passwordOne,
      fields.passwordTwo,
      fields.fullName,
    ];
    if (!inputArray.every(stringNotEmpty)) {
      setError('All inputs are required');
      return true;
    } else if (fields.passwordOne !== fields.passwordTwo) {
      setError("Passwords don't match");
      return true;
    } else if (!tcpp) {
      setError(
        'You cannot signup if you do not agree with the Terms of Service and Privacy Policy'
      );
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const pricing_plan = urlParams.get('plan');
    const inputIncompleteStatus = incompleteInputs();
    if (!inputIncompleteStatus) {
      setSignupInProgress(true);
      window.fbq('track', 'Lead');
      firebase
        .auth()
        .createUserWithEmailAndPassword(fields.emailAddress, fields.passwordOne)
        .then(function () {
          const team_id = fetchedSignupInfo && fetchedSignupInfo.team_id;
          fetchUserAndToken({
            setLoading: setSignupInProgress,
            forceRefresh: false,
            name: fields.fullName,
            team_name: fields.team_name,
            team_id: team_id,
            pricing_plan: pricing_plan,
            userInfoCallback: (userInfo) => {
              identifyUser(userInfo, fields.team_name);
              window.analytics.track('Signed Up');
            },
          });
        })
        .catch(function (error) {
          setSignupInProgress(false);
          var errorCode = error.code;
          if (errorCode === 'auth/weak-password') {
            setError('Password is too weak');
          } else if (errorCode === 'auth/invalid-email') {
            setError('Invalid email');
          } else if (errorCode === 'auth/email-already-in-use') {
            setError('Account exists already. Sign in instead');
          }
        });
    }
  };

  const getStyledInput = (placeholder, fieldName, Input = StyledInput) => {
    return (
      <Input
        placeholder={placeholder}
        onChange={(e) => {
          handleChangeInput(fieldName, e.target.value);
        }}
        size="large"
      />
    );
  };

  return (
    <StyledDiv>
      <Helmet>
        {/* <!-- Facebook Pixel Code --> */}
        <script>
          {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '2221644288099193');
        fbq('track', 'PageView');`}
        </script>
        <noscript>{`<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=2221644288099193&ev=PageView&noscript=1"
        />`}</noscript>
        {/* <!-- End Facebook Pixel Code --> */}
      </Helmet>
      <Logo src={IntersectLogo} title="Intersect Labs" />
      <SignUpForm>
        <Title>Sign Up</Title>

        {getStyledInput('Full name', 'fullName')}
        <StyledInput
          value={fields.team_name}
          placeholder="Company name"
          onChange={(e) => {
            handleChangeInput('team_name', e.target.value);
          }}
          disabled={disabledFields.team_name}
          size="large"
        />
        <StyledInput
          value={fields.emailAddress}
          placeholder="Work email address"
          onChange={(e) => {
            handleChangeInput('emailAddress', e.target.value);
          }}
          disabled={disabledFields.emailAddress}
          size="large"
          type="email"
        />
        {getStyledInput('Password', 'passwordOne', StyledPasswordInput)}
        {getStyledInput(
          'Re-enter password',
          'passwordTwo',
          StyledPasswordInput
        )}

        <ErrorText>{error}</ErrorText>
        <TCPPWrapper>
          <Checkbox onChange={handleChangeTcpp} />
          <TCPPText>
            By signing up, you confirm that you agree with the{' '}
            <a
              href="https://www.intersectlabs.io/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="https://www.intersectlabs.io/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </TCPPText>
        </TCPPWrapper>
        <SubmitButton
          loading={signupInProgress}
          size="large"
          onClick={onSubmit}
          type="primary"
        >
          Sign Up
        </SubmitButton>
      </SignUpForm>
      <SignUpForm>
        <LoginButton />
      </SignUpForm>
    </StyledDiv>
  );
};
