import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  useIsDataAppLoading,
  useIsPublishedOrRunVersionOpen,
} from '../model/DataAppContext';
import { Step } from './Step/Step';
import { values } from 'lodash';
import AppTitleStep from './AppTitleStep';
import { useSteps } from '../model/DataAppStepsContextHelpers';
import { useHistory } from 'react-router';
import { LightBlueButton } from '../standard-components/CustomColorButton';
import { VisibilityOptions } from './Step/StepBody/StepControls';
import { useCurrentInstanceRunStatus } from '../model/DataAppMetadataContextHelpers';
import { ThunderboltTwoTone } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';

const isLastStep = (i, arr) => i === arr.length - 1;

const Row = styled(Space)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0px -35px 30px 0px;

  @media (max-width: 1224px) {
    margin: 0px 0px 15px;
  }
`;

const StepsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  > :last-child {
    padding-bottom: 300px;
  }
`;

const InteractiveIndicator = styled.div`
  padding: 4px 10px;
  border-radius: 6px;
  border: 1px dotted ${(p) => p.theme.color.primary.bright};
  color: ${(p) => p.theme.color.primary.bright};
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  cursor: default;
`;

export const Steps = ({
  hideShowAllSteps = false,
  doNotShowInteractiveTag = false,
}) => {
  const steps = useSteps();
  const appRunStatusIsFailure = useCurrentInstanceRunStatus() === 'FAILURE';
  const isPublishedOrRunVersionOpen = useIsPublishedOrRunVersionOpen();
  const isLoading = useIsDataAppLoading();
  const history = useHistory();
  // Show only inputs and outputs if published or run version is open AND there's no errors in the app run
  const [showOnlyInputsAndOutputs, setShowOnlyInputsAndOutputs] = useState(
    isPublishedOrRunVersionOpen && !appRunStatusIsFailure
  );
  let hasInteractiveSteps = false;

  useEffect(() => {
    setShowOnlyInputsAndOutputs(
      isPublishedOrRunVersionOpen && !appRunStatusIsFailure
    );
  }, [isPublishedOrRunVersionOpen, appRunStatusIsFailure]);

  useEffect(() => {
    const hash = history.location.hash;
    if (hash.startsWith('#step-')) {
      const el = document.getElementById(hash.substring(1));
      if (el) el.scrollIntoView();
    }
  }, [isLoading]);

  let stepsContent = steps.reduce((reducedSteps, step, i) => {
    const updatedSteps = [...reducedSteps];

    const stepComponent = (
      <Step
        key={step.id + step.name + i}
        isLastStep={isLastStep(i, steps)}
        {...step}
        currentStepIndex={reducedSteps.length}
        onlyShowInputsAndOutputs={showOnlyInputsAndOutputs}
        showStepIndex={!showOnlyInputsAndOutputs}
      />
    );

    const stepIsInteractive = values(step.interactive).includes(true);
    hasInteractiveSteps = hasInteractiveSteps || stepIsInteractive;

    if (!showOnlyInputsAndOutputs) {
      updatedSteps.push(stepComponent);
    } else if (
      step.visible === VisibilityOptions.auto ||
      step.visible === true ||
      step.visible === 'True'
    ) {
      if (step.user_provided || step.leaf || stepIsInteractive) {
        updatedSteps.push(stepComponent);
      }
    } else if (step.visible === VisibilityOptions.always) {
      updatedSteps.push(stepComponent);
    }

    return updatedSteps;
  }, []);

  return (
    <StepsContainer>
      <AppTitleStep
        showIntroMessage={!isLoading && steps && steps.length === 0}
      />
      {isPublishedOrRunVersionOpen && (
        <Row size="large">
          {hasInteractiveSteps && !doNotShowInteractiveTag && (
            <Tooltip title="This app is interactive! Scroll down and change something to try it out.">
              <InteractiveIndicator>
                <ThunderboltTwoTone /> Interactive
              </InteractiveIndicator>
            </Tooltip>
          )}
          {!hideShowAllSteps && (
            <LightBlueButton
              onClick={() => setShowOnlyInputsAndOutputs((val) => !val)}
              shape="round"
            >
              {showOnlyInputsAndOutputs
                ? 'Show All Steps'
                : 'Show Only Inputs And Final Steps'}
            </LightBlueButton>
          )}
        </Row>
      )}
      {stepsContent}
    </StepsContainer>
  );
};
